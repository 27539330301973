<template>
  <div class="register">
    <h1 class="title">注册</h1>
    <div class="log">
      已有账户？
      <router-link to="/login">立即登录</router-link>
    </div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
      <el-form-item prop="areaCode">
        <VueCountryIntl v-model="ruleForm.areaCode"></VueCountryIntl>
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input type="text" placeholder="请输入手机号" v-model="ruleForm.phone" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="pass">
        <el-input type="password" placeholder="请输入密码" v-model="ruleForm.pass" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="checkPass">
        <el-input
            type="password"
            placeholder="请输入密码"
            v-model="ruleForm.checkPass"
            autocomplete="off"
        ></el-input>
      </el-form-item>
      <!-- </el-col>
      </el-row>-->
      <el-row type="flex" justify="space-between">
        <el-col :span="14">
          <el-form-item label="验证码" prop="code">
            <el-input type="text" placeholder="请输入验证码" v-model="ruleForm.code" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8" v-if="isShow" style="margin-top:40px">
          <el-button class="send_code" @click="sendCheckCode('ruleForm')">获取验证码</el-button>
        </el-col>
        <el-col :span="8" v-else style="margin-top:40px">
          <el-button class="send_code">({{ seconds }})s</el-button>
        </el-col>
      </el-row>
      <el-form-item class="submit">
        <el-button style='width:100%' type="primary" @click="submitForm('ruleForm')">注册</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {getCodeApi, registerApi} from "api/Register";
import {loginApi} from "api/Login";

export default {
  data() {
    var phone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        callback();
      }
    };
    var code = (rule, value, callback) => {
      //校验验证码
      if (value === "") {
        callback(new Error("请输入验证码"));
      } else {
        callback();
      }
    };
    var areaCode = (rule, value, callback) => {
      //校验区号
      if (value === "") {
        callback(new Error("请选择"));
      } else {
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      //校验密码
      if (value === "") {
        callback(new Error("请输入密码"));
      } else if (!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/.test(value)) {
        callback("密码至少包含数字和英文,长度6-20");
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      //校验密码2
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        phone: "", //手机号
        code: "", //验证码
        pass: "",
        checkPass: "",
        areaCode: "61" //区号
      },
      rules: {
        phone: [{validator: phone, trigger: "blur"}],
        code: [{validator: code, trigger: "blur"}],
        areaCode: [{validator: areaCode, trigger: "blur"}],
        pass: [{validator: validatePass, trigger: "blur"}],
        checkPass: [{validator: validatePass2, trigger: "blur"}]
      },
      isShow: true,
      seconds: "",
      timer: null
    };
  },
  methods: {
    //注册提交
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const {phone, code, areaCode, pass} = this.ruleForm;
          registerApi({
            phone: "+" + areaCode + " " + phone,
            code,
            password: pass,
            invite_code: this.invite_code
          }).then(res => {
            if (res.code == 0) {
              const params = {
                phone: "+" + areaCode + " " + phone,
                password: pass
              };
              loginApi(params).then(res => {
                if (res.code == 0) {
                  this.$store.dispatch({
                    type: "changeLoginStatus",
                    login: true
                  });
                  sessionStorage.setItem("token", res.data);
                  this.$router.push({path: "/admin_center"});
                }
              });
              // this.$router.push({ path: "/login" });
            }
          });
        } else {
          return false;
        }
      });
    },
    //发送验证码
    sendCheckCode(formName) {
      this.$refs[formName].validateField("phone", error => {
        if (!error) {
          const TIME_seconds = 15;
          if (!this.timer) {
            this.seconds = TIME_seconds;
            this.isShow = false;
            this.timer = setInterval(() => {
              if (this.seconds > 0) {
                this.seconds--;
              } else {
                this.isShow = true;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
            const {phone, areaCode} = this.ruleForm;
            getCodeApi({phone: "+" + areaCode + " " + phone});
          }
        }
      });
    }
  },
  mounted() {
    if (this.$route.query.invite_code) {
      this.invite_code = this.$route.query.invite_code;
    }
  }
};
</script>
<style scoped lang='scss'>
.register {
  padding: 16px;
  padding-top: 80px;

  ::v-deep .vue-country-intl {
    .country-intl-label {
      width: auto !important;
      padding: 0 0 0 10px !important;
    }

    .country-intl-input-wrap {
      width: 100% !important;
      box-sizing: border-box !important;
    }

    .country-intl-input {
      width: 100% !important;
      box-sizing: border-box;
    }

    .vue-country-list {
      width: 100% !important;

      li {
        padding: 0 10px !important;
        box-sizing: border-box;
        text-align: left;
      }
    }
  }

  .title {
    padding-bottom: 35px;
    font-size: 30px;
    color: #517cfc;
    text-align: center;
  }

  .log {
    text-align: right;
    padding-bottom: 10px;

    a {
      color: #517cfc;
      text-decoration: underline;
    }
  }

  .el-input_inner {
    border: 1px solid #cdcdcd;
  }

  .el-button--primary {
    width: 100%;
    background-color: #517cfc;
    border-color: #517cfc;
  }

  .send_code {
    width: 100%;
    background: #517cfc;
    color: #fff;
  }

  .submit {
    margin-top: 15px;
    margin-bottom: 20px;
  }
}
</style>