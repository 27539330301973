import Ajax from './http';

//获取验证码
export function getCodeApi(values) {
	return Ajax.post('/register/send_phone_code', values);
}
//注册
export function registerApi(values) {
	return Ajax.post('/register/phone', values);
}
